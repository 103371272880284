import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

const IndexPage = () => ( 
  <Layout>

    <Helmet>
        <title>Brian Stewart - Home - Solution architect, software engineer, and product technology professional living in Asheville, North Carolina</title>
    </Helmet>
    
    <div className="index-container">
      <h1><strong>LATEST</strong></h1>
      <div>
          <div>
            <h2>Static Website Hosting on Azure Storage</h2>
            <div>June 1</div>
            <p>An ideal base setup for hosting a static website or client app on Azure blob storage using an apex domain and HTTPS / SSL.  In Part 1 of this multi-part series, we'll setup base DNS, create our storage account, and deploy to it.  We'll finish with a working website!</p>
            <p><Link to="blog/azure-static-website-https-custom-domain">Continue reading...</Link></p>
          </div>
      </div>
    </div>

  </Layout>
)

export default IndexPage
